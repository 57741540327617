import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
}

export const StatusLayout = ({ children }: Props) => (
  <div className="status-layout">
    <div className="title">
      <FontAwesomeIcon icon="shield-cat" />
      CATS
    </div>
    <em className="subtitle">Certificate Administration and Tracking System</em>
    <div className="status">{children}</div>
  </div>
);
