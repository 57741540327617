import { ReactNode, useState } from 'react';
import { Form } from '../Form';
import { ButtonGroup } from '../ButtonGroup';
import { Button } from '../Button';

type ChildFn = (props: { editing: boolean }) => ReactNode;

export interface Props {
  children: ReactNode | ChildFn;
  onSubmit: () => void | Promise<void>;
  onCancel?: () => void;
  onEdit?: () => void;
  className?: string;
}

export const EditableForm = ({
  children,
  onSubmit,
  onCancel,
  onEdit,
  className,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  return (
    <Form
      onSubmit={async () => {
        setSaving(true);
        try {
          await onSubmit();
          setEditing(false);
        } finally {
          setSaving(false);
        }
      }}
      className={className}
    >
      {typeof children === 'function' ? children({ editing }) : children}
      <div className="buttons">
        {editing ? (
          <ButtonGroup>
            <Button
              type="submit"
              text="Opslaan"
              icon="check"
              loading={saving}
            />
            <Button
              text="Annuleren"
              icon="xmark"
              onClick={() => {
                setEditing(false);
                onCancel && onCancel();
              }}
              disabled={saving}
            />
          </ButtonGroup>
        ) : (
          <Button
            text="Bewerken"
            icon="pencil"
            onClick={() => {
              setEditing(true);
              onEdit && onEdit();
            }}
          />
        )}
      </div>
    </Form>
  );
};
