import { JsonApiService } from './JsonApiService';
import { Injector } from 'react-service-injector';
import { Page, PageParameters } from '../model/Page';
import { CreateShare, Share, UpdateShare } from '../model/Share';

export class SharesService {
  private readonly api: JsonApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(JsonApiService);
    this.listShares = this.listShares.bind(this);
  }

  public listShares(page: PageParameters): Promise<Page<Share>> {
    return this.api.jsonGet('/api/shares', { ...page });
  }

  public createShare(share: CreateShare): Promise<Share> {
    return this.api.jsonPost('/api/shares', share);
  }

  public getShare(id: number): Promise<Share> {
    return this.api.jsonGet(`/api/shares/${id}`);
  }

  public updateShare(id: number, updates: UpdateShare): Promise<Share> {
    return this.api.jsonPut(`/api/shares/${id}`, updates);
  }
}
