import { Props as TextAreaProps, TextArea } from '../TextArea';

export interface Props extends TextAreaProps {
  editing: boolean;
  editValue: string | number | undefined;
}

export const EditableTextArea = ({ editing, editValue, ...props }: Props) =>
  editing ? (
    <TextArea {...props} value={editValue} />
  ) : (
    <span className="textarea">{props.value || '-'}</span>
  );
