import { Form } from '../form/Form';
import { CloseSubmitProps, Modal } from '../Modal';
import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { CreateAdmin } from '../../model/Admin';
import { Input } from '../form/Input';

export const CreateAdminModal = ({ onClose, onSubmit }: CloseSubmitProps) => {
  const api = useApi();
  const [admin, setField] = useFormValue<CreateAdmin>(() => ({
    email: '',
  }));

  return (
    <Modal title="Nieuwe admin" onClose={onClose}>
      <Form
        className="rows"
        submitLabel="Opslaan"
        onSubmit={() =>
          api.admins.createAdmin(admin).then(() => {
            onSubmit();
            onClose();
          })
        }
      >
        <div>
          <label>E-mail</label>
          <Input
            value={admin.email}
            onChange={setField('email')}
            required
            type="email"
          />
        </div>
      </Form>
    </Modal>
  );
};
