import { useCallback, useEffect, useState } from 'react';
import { Menu } from './Menu';
import { TopBar } from './TopBar';
import { Outlet } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';

export const Layout = () => {
  const [showMenu, setShowMenu] = useState(false);
  const api = useApi();
  const [checksCount, setChecksCount] = useState(0);

  const getChecksCount = useCallback(
    () => api.checks.getChecks().then((r) => setChecksCount(r.count)),
    [api.checks]
  );

  useEffect(() => {
    getChecksCount();

    const interval = setInterval(getChecksCount, 10000);
    return () => clearInterval(interval);
  }, [getChecksCount]);

  return (
    <div className="layout">
      <Menu
        visible={showMenu}
        onClose={() => setShowMenu(false)}
        checksCount={checksCount}
      />
      <div className="main-wrapper">
        <TopBar
          showMenu={showMenu}
          onToggleMenu={() => setShowMenu((prevState) => !prevState)}
        />
        <Outlet />
      </div>
    </div>
  );
};
