export function formatPercent(ratio: number): string {
  return `${(ratio * 100).toFixed(2)} %`;
}

export function formatPercentWhole(percent: number): string {
  return `${percent} %`;
}

export function toTwoDigitsNumber(ratio: number): number {
  return parseFloat((ratio * 100).toFixed(2));
}
