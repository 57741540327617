import { JsonApiService } from './JsonApiService';
import { Injector } from 'react-service-injector';
import { Checks } from '../model/Checks';

export class ChecksService {
  private readonly api: JsonApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(JsonApiService);
  }

  public getChecks(): Promise<Checks> {
    return this.api.jsonGet('/api/checks');
  }
}
