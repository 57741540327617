import { JsonApiService } from './JsonApiService';
import { Injector } from 'react-service-injector';
import { MemberIncomeTax } from '../model/MemberIncomeTax';

export class IncomeTaxService {
  private readonly api: JsonApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(JsonApiService);
  }

  public getMemberIncomeTax(): Promise<MemberIncomeTax> {
    return this.api.jsonGet('/api/income-tax');
  }
}
