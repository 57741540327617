import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, ReactNode } from 'react';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { classNames } from '../utils/classNames';

export interface Props {
  data: Row[];
  noIcons?: boolean;
}

export interface Row {
  icon?: IconName | ReactElement;
  key: string;
  value: ReactNode;
}

export const ItemRows = ({ data, noIcons }: Props) => (
  <div className={classNames('item-rows', noIcons && 'no-icons')}>
    {data.map((d, i) => (
      <React.Fragment key={i}>
        {d.icon && (
          <div className="icon">
            {typeof d.icon === 'string' ? (
              <FontAwesomeIcon icon={d.icon} />
            ) : (
              d.icon
            )}
          </div>
        )}
        <div className="key">{d.key}</div>
        <div className="value">{d.value}</div>
      </React.Fragment>
    ))}
  </div>
);
