import { MembersInTime } from '../../model/Stats';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatDate } from '../../utils/date';
import { splineSeries } from './series';

export interface Props {
  membersOverTime: MembersInTime[];
}

export const MembersChart = ({ membersOverTime }: Props) => {
  const options: Options = {
    title: {
      text: 'Deelnemers over tijd',
    },
    xAxis: {
      categories: membersOverTime.map((v) => formatDate(v.date)),
    },
    yAxis: {
      title: {
        text: 'Aantal deelnemers',
      },
      min: 0,
    },
    series: [
      {
        ...splineSeries(0),
        name: 'Deelnemers',
        data: membersOverTime.map((m) => m.members),
      },
    ],
    tooltip: {
      shared: true,
    },
  };

  return (
    <div className="chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
