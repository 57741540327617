import { ReactElement } from 'react';
import { TextInputType } from '../TextInput';
import { ItemRows } from '../../ItemRows';
import { setFormField } from '../../../hooks/useFormValue';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { NumberInputType } from '../NumberInput';
import { EditableInput } from './EditableInput';
import { EditableTextArea } from './EditableTextArea';

export interface Props<T> {
  currentValue: T;
  value: T;
  editing: boolean;
  rows: Row<T>[];
  setUpdatedField: setFormField<T>;
}

export type Row<T> = KeyRow<T> | FnRow;

interface BaseRow {
  name: string;
  icon: IconName | ReactElement;
  type?: TextInputType | NumberInputType | 'textarea';
  required?: boolean;
  editable?: boolean;
}

interface KeyRow<T> extends BaseRow {
  key: keyof T;
}

interface FnRow extends BaseRow {
  valueFn: () => string | number;
}

export function EditableColumns<
  T extends Record<string, string | number | undefined>
>({ currentValue, value, editing, rows, setUpdatedField }: Props<T>) {
  return (
    <ItemRows
      data={rows.map((r) => {
        const isKeyRow = 'key' in r;
        // FnRows are never editable.
        const editable = isKeyRow && r.editable !== false;

        const curVal = isKeyRow ? currentValue[r.key] : r.valueFn();
        const editVal = isKeyRow ? value[r.key] : r.valueFn();
        const onChange = isKeyRow
          ? (e: unknown) => setUpdatedField(r.key)(e as never)
          : () => undefined;

        return {
          key: r.name,
          icon: r.icon,
          value:
            r.type === 'textarea' ? (
              <EditableTextArea
                editing={editable && editing}
                editValue={editVal}
                value={curVal}
                onChange={onChange}
              />
            ) : (
              <EditableInput
                editing={editable && editing}
                type={r.type}
                currentValue={curVal as never}
                value={editVal as never}
                onChange={onChange}
                required={r.required}
              />
            ),
        };
      })}
    />
  );
}
