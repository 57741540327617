import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import {
  isTextInputType,
  Props as TextInputProps,
  TextInput,
} from './TextInput';
import {
  NumberInput,
  NumberInputType,
  Props as NumberInputProps,
} from './NumberInput';

export type HtmlInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type Props =
  | TextInputProps
  | (NumberInputProps & {
      type: NumberInputType;
    });

export const Input = (props: Props) =>
  isTextInputProps(props) ? (
    <TextInput {...props} />
  ) : (
    <NumberInput {...props} />
  );

export function isTextInputProps(props: Props): props is TextInputProps {
  return !props.type || isTextInputType(props.type);
}
