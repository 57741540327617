import { IconName } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { classNames } from '../utils/classNames';

export interface Props {
  title: string;
  icon: IconName;
  children: ReactNode;
  className?: string;
}

export const TitleBlock = ({ title, icon, children, className }: Props) => (
  <div className={classNames('title-block', className)}>
    <div className="title">
      <FontAwesomeIcon icon={icon} className="icon" />
      {title}
    </div>
    {children}
  </div>
);
