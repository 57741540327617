import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { classNames } from '../../utils/classNames';

export interface Props<D> {
  data: D[] | undefined;
  columns: Columns<D>;
  loading?: boolean;
  onClickRow?: (row: D) => void;
  title?: string;
  actions?: ((row: D) => ReactNode) | false;
}

export interface Column<D> {
  name: string;
  valueFn: (value: D) => ReactNode;
  className?: string;
}

export type Columns<D> = (Column<D> | undefined | false)[];

export function Table<D>({
  data,
  columns,
  loading,
  onClickRow,
  title,
  actions,
}: Props<D>) {
  const filteredColumns = columns.filter((c) => !!c) as Column<D>[];

  if (actions) {
    filteredColumns.push({
      name: '',
      valueFn: actions,
    });
  }

  return (
    <div className="table">
      {title && <h2 className="title">{title}</h2>}
      <table>
        <colgroup>
          {filteredColumns.map((col, i) => (
            <col
              key={i}
              className={classNames(
                actions && i === filteredColumns.length - 1 && 'actions',
                col.className
              )}
            />
          ))}
        </colgroup>
        <thead>
          <tr>
            {filteredColumns.map((col, i) => (
              <th key={i} className={col.className}>
                {col.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data ? (
            data.map((row, rowI) => (
              <tr
                key={rowI}
                className={classNames(onClickRow && 'clickable')}
                onClick={onClickRow && (() => onClickRow(row))}
              >
                {filteredColumns.map((col, i) => (
                  <td
                    key={i}
                    className={classNames(
                      actions && i === filteredColumns.length - 1 && 'actions',
                      col.className
                    )}
                  >
                    {col.valueFn(row)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className="placeholder-row">
              <td colSpan={columns.length} />
            </tr>
          )}
          {(loading || !data) && (
            <tr className="loader">
              <td>
                <FontAwesomeIcon icon="spinner" pulse />
                Laden...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
