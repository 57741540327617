import { StatCard, StatCards } from '../components/StatCard';
import React, { useEffect, useState } from 'react';
import { Stats } from '../model/Stats';
import { useApi } from '../hooks/useApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPercent } from '../utils/percent';
import { formatEuro } from '../utils/currency';
import { ValueChart } from '../components/charts/ValueChart';
import { CertificatesChart } from '../components/charts/CertificatesChart';
import { TradeVolumeChart } from '../components/charts/TradeVolumeChart';
import { MembersChart } from '../components/charts/MembersChart';

export const OverviewPage = () => {
  const [stats, setStats] = useState<Stats>();
  const api = useApi();

  useEffect(() => {
    api.stats.getStats().then(setStats);
  }, [api]);

  return (
    <main className="overview-page">
      <div className="header">
        <div>
          <h1>Welkom bij CATS</h1>
          <em>Certificate Administration and Tracking System</em>
        </div>
      </div>
      {stats ? (
        <StatCards colored>
          <StatCard value={stats.members} label="Certificaathouders" />
          <StatCard
            value={stats.assignedCerts}
            label="Toegewezen certificaten"
          />
          <StatCard
            value={stats.totalCerts - stats.assignedCerts}
            label="Vrije certificaten"
          />
          <StatCard
            value={formatPercent(stats.employeeOwnedRatio)}
            label="Totale belang"
          />
          <StatCard
            value={formatEuro(stats.certValue)}
            label="Certificaatwaarde"
          />
          <ValueChart valueOverTime={stats.valueOverTime} />
          <CertificatesChart certsOverTime={stats.certsOverTime} />
          <TradeVolumeChart tradeVolumeOverTime={stats.tradeVolumeOverTime} />
          <MembersChart membersOverTime={stats.membersOverTime} />
        </StatCards>
      ) : (
        <FontAwesomeIcon icon="spinner" pulse className="spinner" size="2x" />
      )}
    </main>
  );
};
