import { useApi } from '../hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { PageLoading } from '../components/layout/PageLoading';
import { usePagedData } from '../hooks/usePagedData';
import { useRequiredIntParam } from '../hooks/useRequiredIntParam';
import { MemberSummary, UpdateMember } from '../model/Member';
import { CreateTransactionsModal } from '../components/modals/CreateTransactionsModal';
import { Button } from '../components/form/Button';
import { Link } from 'react-router-dom';
import { CertificatesTable } from '../components/tables/CertificatesTable';
import { TransactionsTable } from '../components/tables/TransactionsTable';
import { useFormValue } from '../hooks/useFormValue';
import { EditableForm } from '../components/form/editable/EditableForm';
import { EditableColumns } from '../components/form/editable/EditableColumns';
import { Toggle } from '../components/form/Toggle';
import { StatCard, StatCards } from '../components/StatCard';
import { ActionsBlock } from '../components/ActionsBlock';

export const MemberPage = () => {
  const api = useApi();
  const memberId = useRequiredIntParam('memberId');

  const [member, setMember] = useState<MemberSummary>();
  const [updatedMember, setUpdatedField, setUpdatedMember] =
    useFormValue<UpdateMember>({ name: '', email: '', notes: '' });

  const [groupTransactions, setGroupTransactions] = useState(true);
  const transactions = usePagedData(api.transactions.listTransactions, {
    memberId,
    group: groupTransactions,
  });
  const certificates = usePagedData(api.certificates.listCertificates, {
    memberId,
  });

  const [newTransactionsModal, setNewTransactionsModal] = useState(false);

  useEffect(() => {
    api.members.getMember(memberId).then(setMember);
  }, [api, memberId]);

  const submitTransactions = useCallback(() => {
    transactions.reloadPage();
    certificates.reloadPage();
    api.members.getMember(memberId).then(setMember);
  }, [api, certificates, memberId, transactions]);

  const startEditing = useCallback(() => {
    if (!member) {
      return;
    }

    setUpdatedMember({ ...member });
  }, [member, setUpdatedMember]);

  const refreshMember = useCallback(
    () => api.members.getMember(memberId).then(setMember),
    [api, memberId]
  );

  const saveEdits = useCallback(async () => {
    await api.members.updateMember(memberId, updatedMember);
    await refreshMember();
  }, [api, memberId, refreshMember, updatedMember]);

  if (!member) {
    return <PageLoading />;
  }

  return (
    <>
      <main className="member-page">
        <div className="header">
          <div>
            <h1>
              <Link to="/members">Deelnemers</Link> / {member.name}
              {member.archived && <em> (gearchiveerd)</em>}
            </h1>
          </div>
        </div>
        <div className="info-columns">
          <EditableForm
            className="section"
            onSubmit={saveEdits}
            onEdit={startEditing}
          >
            {({ editing }) => (
              <EditableColumns
                currentValue={member}
                value={updatedMember}
                editing={editing}
                setUpdatedField={setUpdatedField}
                rows={[
                  {
                    name: 'Naam',
                    key: 'name',
                    icon: 'user',
                    required: true,
                  },
                  {
                    name: 'E-mail',
                    key: 'email',
                    icon: 'envelope',
                    required: true,
                    type: 'email',
                  },
                  {
                    name: 'Startdatum',
                    key: 'startDate',
                    icon: 'calendar-day',
                    type: 'date',
                  },
                  {
                    name: 'Einddatum',
                    key: 'endDate',
                    icon: 'calendar-xmark',
                    type: 'date',
                  },
                  {
                    name: 'Notities',
                    key: 'notes',
                    icon: 'clipboard-list',
                    type: 'textarea',
                  },
                ]}
              />
            )}
          </EditableForm>
          <ActionsBlock>
            {member.archived ? (
              <Button
                text="Dearchiveren"
                icon="boxes-packing"
                onClick={() =>
                  api.members.unarchiveMember(member.id).then(refreshMember)
                }
              />
            ) : (
              <>
                <Button
                  text="Nieuwe transacties"
                  icon="right-left"
                  onClick={() => setNewTransactionsModal(true)}
                  kind="primary"
                />
                <Button
                  text="Archiveren"
                  icon="archive"
                  onClick={() =>
                    api.members.archiveMember(member.id).then(refreshMember)
                  }
                />
              </>
            )}
          </ActionsBlock>
          <StatCards>
            <StatCard value={member.certificatesCount} label="Certificaten" />
          </StatCards>
        </div>
        <div className="section">
          <div className="sub-header">
            <h2>Transacties</h2>
          </div>
          <div className="filters">
            <Toggle
              checked={groupTransactions}
              onChange={setGroupTransactions}
              label="Groeperen"
            />
          </div>
          <TransactionsTable
            transactions={transactions}
            memberId={memberId}
            group={groupTransactions}
          />
        </div>
        <div>
          <div className="sub-header">
            <h2>Certificaten</h2>
          </div>
          <CertificatesTable certificates={certificates} memberId={memberId} />
        </div>
      </main>
      {newTransactionsModal && (
        <CreateTransactionsModal
          onClose={() => setNewTransactionsModal(false)}
          onSubmit={submitTransactions}
          memberId={memberId}
        />
      )}
    </>
  );
};
