import { useApi } from '../hooks/useApi';
import { useRequiredIntParam } from '../hooks/useRequiredIntParam';
import { Link } from 'react-router-dom';
import { PageLoading } from '../components/layout/PageLoading';
import { useCallback, useEffect, useState } from 'react';
import { Share, UpdateShare } from '../model/Share';
import { useFormValue } from '../hooks/useFormValue';
import { EditableForm } from '../components/form/editable/EditableForm';
import { EditableColumns } from '../components/form/editable/EditableColumns';

export const SharePage = () => {
  const api = useApi();
  const shareId = useRequiredIntParam('shareId');

  const [share, setShare] = useState<Share>();
  const [updatedShare, setUpdatedField, setUpdatedShare] =
    useFormValue<UpdateShare>({ name: '', acquireDate: '', notes: '' });

  useEffect(() => {
    api.shares.getShare(shareId).then(setShare);
  }, [api, shareId]);

  const startEditing = useCallback(() => {
    if (!share) {
      return;
    }

    setUpdatedShare({ ...share });
  }, [setUpdatedShare, share]);

  const saveEdits = useCallback(() => {
    return api.shares.updateShare(shareId, updatedShare).then(setShare);
  }, [api, shareId, updatedShare]);

  if (!share) {
    return <PageLoading />;
  }

  return (
    <main className="share-page">
      <div className="header">
        <h1>
          <Link to="/shares">Aandelen</Link> / {share.name}
        </h1>
      </div>
      <EditableForm onSubmit={saveEdits} onEdit={startEditing}>
        {({ editing }) => (
          <EditableColumns
            currentValue={share}
            value={updatedShare}
            editing={editing}
            setUpdatedField={setUpdatedField}
            rows={[
              {
                name: 'Naam',
                key: 'name',
                icon: 'file-contract',
                required: true,
              },
              {
                name: 'Verkregen',
                key: 'acquireDate',
                icon: 'calendar-day',
                type: 'date',
                required: true,
              },
              {
                name: 'Notities',
                key: 'notes',
                icon: 'clipboard-list',
                type: 'textarea',
              },
            ]}
          />
        )}
      </EditableForm>
    </main>
  );
};
