export interface Props {
  text: string | number;
  onClick: () => void;
}

export const ButtonLink = ({ text, ...props }: Props) => (
  <button className="link" {...props}>
    {text}
  </button>
);
