const LINE_COLORS = ['#269bc9', '#804ff9'];

export interface SplineSeries {
  type: 'spline';
  color: string;
}

export function splineSeries(index: number): SplineSeries {
  return {
    type: 'spline',
    color: LINE_COLORS[index],
  };
}
