import { CloseProps, Modal } from '../Modal';
import React, { useEffect, useState } from 'react';
import { Certificate } from '../../model/Certificate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useService } from 'react-service-injector';
import { TransactionsService } from '../../services/TransactionsService';
import {
  TransactionGroup,
  transactionTypeNames,
} from '../../model/Transaction';
import { formatDate } from '../../utils/date';

export interface Props extends CloseProps {
  group: TransactionGroup;
}

export const TransactionCertificatesModal = ({ onClose, group }: Props) => {
  const transactions = useService(TransactionsService);
  const [certs, setCerts] = useState<Certificate[]>();

  useEffect(() => {
    transactions
      .getAllTransactions({
        memberId: group.member.id,
        date: group.date,
        type: group.type,
      })
      .then((transactions) => transactions.map((t) => t.certificate))
      .then(setCerts);
  }, [group, transactions]);

  return (
    <Modal title="Certificaten" onClose={onClose}>
      <h3>
        {group.member.name}, {formatDate(group.date)},{' '}
        {transactionTypeNames[group.type].toLowerCase()}
      </h3>
      {certs ? (
        certs.map((c) => c.fullName).join(', ')
      ) : (
        <FontAwesomeIcon icon="spinner" pulse className="spinner" />
      )}
    </Modal>
  );
};
