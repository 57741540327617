import { useState } from 'react';
import { useFormValue } from '../hooks/useFormValue';
import {
  CreateTradeRoundRegistration,
  tradeRoundRegistrationTypeOptions,
} from '../model/TradeRound';
import { Form } from './form/Form';
import { Input } from './form/Input';
import { Select } from './form/Select';
import { formatEuro } from '../utils/currency';
import { transactionTypeNames } from '../model/Transaction';

export interface Props {
  certPrice: number;
  onSubmit: (registration: CreateTradeRoundRegistration) => void;
  maxBuyable: number;
  maxSellable: number;
}

export const TradeRoundRegistrationForm = ({
  certPrice,
  onSubmit,
  maxBuyable,
  maxSellable,
}: Props) => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [reg, setField] = useFormValue<CreateTradeRoundRegistration>({
    type: 'buy',
    requestedAmount: 1,
  });

  return (
    <Form
      onSubmit={() => onSubmit(reg)}
      submitLabel="Inschrijven"
      submitDisabled={!termsAgreed}
      className="rows small"
    >
      <div>
        <label>Type</label>
        <Select
          value={reg.type}
          onChange={setField('type')}
          options={tradeRoundRegistrationTypeOptions}
          required
        />
      </div>
      <div>
        <label>
          Aantal (max. {reg.type === 'buy' ? maxBuyable : maxSellable})
        </label>
        <Input
          value={reg.requestedAmount}
          onChange={setField('requestedAmount')}
          required
          type="number"
          min={1}
          max={reg.type === 'buy' ? maxBuyable : maxSellable}
        />
      </div>
      <div>
        <label>Bedrag</label>
        <div>
          {reg.requestedAmount} x {formatEuro(certPrice)} ={' '}
          {formatEuro(reg.requestedAmount * certPrice)}
        </div>
      </div>
      <div>
        <label htmlFor="terms-conditions">
          Ik ga akkoord met de voorwaarden verbonden aan de{' '}
          {transactionTypeNames[reg.type].toLowerCase()}, zoals genoemd in het
          participatiereglement.
        </label>
        <input
          type="checkbox"
          id="terms-conditions"
          checked={termsAgreed}
          onChange={(e) => setTermsAgreed(e.currentTarget.checked)}
        />
      </div>
    </Form>
  );
};
