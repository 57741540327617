import { PaginatedTable } from '../components/table/PaginatedTable';
import { useApi } from '../hooks/useApi';
import { usePagedData } from '../hooks/usePagedData';
import { useState } from 'react';
import { CreateMemberModal } from '../components/modals/CreateMemberModal';
import { Button } from '../components/form/Button';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/date';
import { Toggle } from '../components/form/Toggle';
import { Input } from '../components/form/Input';

export const MembersPage = () => {
  const api = useApi();
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState('');
  const membersData = usePagedData(api.members.listMembers, {
    archived,
    search,
  });
  const [createMemberModal, setCreateMemberModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <main className="members-page">
        <div className="header">
          <h1>Deelnemers</h1>
          <Button
            text="Nieuwe deelnemer"
            icon="plus"
            onClick={() => setCreateMemberModal(true)}
            kind="primary"
          />
        </div>
        <div className="filters">
          <Input value={search} onChange={setSearch} placeholder="Zoek..." />
          <Toggle
            checked={archived}
            onChange={setArchived}
            label="Gearchiveerd"
          />
        </div>
        <PaginatedTable
          pagedData={membersData}
          onClickRow={(m) => navigate(`/members/${m.id}`)}
          columns={[
            {
              name: 'Naam',
              valueFn: (m) => m.name,
            },
            {
              name: 'E-mail',
              valueFn: (m) => m.email,
              className: 'hide-large',
            },
            {
              name: 'Startdatum',
              valueFn: (m) => formatDate(m.startDate),
            },
            {
              name: 'Einddatum',
              valueFn: (m) => formatDate(m.endDate),
              className: 'hide-small',
            },
            !archived && {
              name: 'Certificaten',
              valueFn: (m) => m.certificatesCount,
              className: 'hide-small',
            },
          ]}
        />
      </main>
      {createMemberModal && (
        <CreateMemberModal
          onClose={() => setCreateMemberModal(false)}
          onSubmit={(m) => navigate(`/members/${m.id}`)}
        />
      )}
    </>
  );
};
