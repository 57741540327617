import { CertificatesTable } from '../components/tables/CertificatesTable';
import { useApi } from '../hooks/useApi';
import { usePagedData } from '../hooks/usePagedData';
import { useAssertUser } from '../hooks/useUser';

export const CertificatesPage = () => {
  const api = useApi();
  const user = useAssertUser();
  const certificates = usePagedData(api.certificates.listCertificates);

  return (
    <main className="certificates-page">
      <div className="header">
        <h1>Certificaten</h1>
      </div>
      <CertificatesTable
        certificates={certificates}
        memberId={user.isAdmin ? undefined : user.memberId}
      />
    </main>
  );
};
