import { Member } from './Member';
import { Option } from '../components/form/Select';
import { Certificate } from './Certificate';

export interface Transaction {
  id: number;
  date: string;
  member: Member;
  type: TransactionType;
  certificate: Certificate;
  price: number;
}

export interface TransactionGroup
  extends Omit<Transaction, 'id' | 'certificate'> {
  certificatesCount: number;
  certificate: undefined;
}

export type TransactionType = 'buy' | 'sell' | 'gift';

export interface TransactionsFilter {
  memberId?: number;
  group?: boolean;
  date?: string;
  type?: TransactionType;
}

export interface CreateTransactions {
  memberId: number;
  type: TransactionType;
  amount: number;
  date: string;
}

export const transactionTypeNames: Record<TransactionType, string> = {
  buy: 'Koop',
  sell: 'Verkoop',
  gift: 'Gift',
};

export const transactionTypeOptions: Option<TransactionType>[] = [
  {
    value: 'buy',
    displayValue: transactionTypeNames.buy,
  },
  {
    value: 'sell',
    displayValue: transactionTypeNames.sell,
  },
  {
    value: 'gift',
    displayValue: transactionTypeNames.gift,
  },
];
