import { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';

export type HtmlTextAreaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export interface Props extends Omit<HtmlTextAreaProps, 'onChange'> {
  value: string | number | undefined;
  onChange: (value: string) => void;
}

export const TextArea = ({ onChange, ...props }: Props) => (
  <textarea {...props} onChange={(e) => onChange(e.currentTarget.value)} />
);
