import { Pagination } from '../Pagination';
import { PagedData } from '../../hooks/usePagedData';
import { Columns, Table } from './Table';
import { ReactNode, useEffect } from 'react';

export interface Props<D> {
  pagedData: PagedData<D>;
  columns: Columns<D>;
  onClickRow?: (row: D) => void;
  title?: string;
  actions?: ((row: D) => ReactNode) | false;
}

export function PaginatedTable<D>({ pagedData, ...props }: Props<D>) {
  useEffect(() => {
    // If we are on a page beyond the page count, and there are pages, clamp the page number.
    const meta = pagedData.page?.meta;
    if (meta && meta.pageCount > 0 && meta.page > meta.pageCount) {
      pagedData.setPageNum(meta.pageCount);
    }
  }, [pagedData]);

  return (
    <div className="paginated-table">
      <Table
        {...props}
        data={pagedData.page?.data}
        loading={pagedData.loading}
      />
      {pagedData.page && (
        <Pagination page={pagedData.page} onNavigate={pagedData.setPageNum} />
      )}
    </div>
  );
}
