import { Input, Props as InputProps } from './Input';
import { useState } from 'react';
import { Button } from './Button';

export type Props = InputProps;

export const InlineEditableInput = ({ value, onChange, ...props }: Props) => {
  const [editing, setEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);

  return (
    <span className="inline-editable-input">
      <Input
        {...props}
        onChange={(e: string | number | undefined) => setNewValue(e)}
        value={newValue as never}
        readOnly={!editing}
      />
      <span className="buttons">
        {editing ? (
          <>
            <Button
              onClick={() => {
                setEditing(false);
                setNewValue(value);
              }}
              icon="times"
            />
            <Button
              onClick={() => {
                Promise.resolve(onChange(newValue as never)).then(() =>
                  setEditing(false)
                );
              }}
              icon="check"
              kind="primary"
            />
          </>
        ) : (
          <Button onClick={() => setEditing(true)} icon="pencil" />
        )}
      </span>
    </span>
  );
};
