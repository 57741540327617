import { useApi } from '../hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import {
  CreateTradeRoundRegistration,
  TradeRoundStatus,
} from '../model/TradeRound';
import { PageLoading } from '../components/layout/PageLoading';
import { formatDate } from '../utils/date';
import { TradeRoundRegistrationForm } from '../components/TradeRoundRegistrationForm';
import { transactionTypeNames } from '../model/Transaction';

export const CurrentTradeRoundPage = () => {
  const api = useApi();
  const [status, setStatus] = useState<TradeRoundStatus>();
  const [certPrice, setCertPrice] = useState<number>();

  useEffect(() => {
    api.trading.getTradeRoundStatus().then(setStatus);
    api.stats.getCertPrice().then(setCertPrice);
  }, [api]);

  const registerAndRefresh = useCallback(
    async (reg: CreateTradeRoundRegistration) => {
      await api.trading.createTradeRoundRegistration(reg);
      api.trading.getTradeRoundStatus().then(setStatus);
    },
    [api]
  );

  if (!status || !certPrice) {
    return <PageLoading />;
  }

  return (
    <main className="current-trade-round-page">
      <div className="header">
        <h1>Handelsronde</h1>
      </div>
      {!status.current && status.upcoming && (
        <p>
          De volgende handelsronde begint op{' '}
          {formatDate(status.upcoming.startDate)}.
        </p>
      )}
      {!status.current && !status.upcoming && (
        <p>Er is op het moment geen handelsronde actief of gepland.</p>
      )}
      {status.current && (
        <div className="section">
          <p>
            De huidige handelsronde loopt van{' '}
            {formatDate(status.current.startDate)} t/m{' '}
            {formatDate(status.current.endDate)}.
          </p>
          <h2 className="sub-header">Inschrijven</h2>
          {status.registration ? (
            <p>
              Je hebt je ingeschreven voor de handelsronde voor de{' '}
              {transactionTypeNames[status.registration.type].toLowerCase()} van{' '}
              {status.registration.requestedAmount} certificaten.
            </p>
          ) : (
            <TradeRoundRegistrationForm
              certPrice={certPrice}
              onSubmit={registerAndRefresh}
              maxBuyable={status.maxBuyableCerts}
              maxSellable={status.maxSellableCerts}
            />
          )}
        </div>
      )}
      <div className="section">
        <h2>Regels rondom koop/verkoop certificaten</h2>
        <p>
          De belangrijkste regels rondom de koop en verkoop van certificaten bij
          de handelsronde op een rijtje. De secties verwijzen naar het
          participatiereglement.
        </p>
        <h3 className="sub-header">Koop</h3>
        <ul>
          <li>
            Het maximum aantal certificaten wat je in bezit mag hebben is 220
            plus 220 voor elk heel jaar na aanvang van het
            certificaathouderschap, tot een limiet van 1100 (sectie 1.3).
          </li>
        </ul>
        <h3 className="sub-header">Verkoop</h3>
        <ul>
          <li>
            De 10 geschonken certificaten mogen niet verkocht worden (sectie
            1.2).
          </li>
          <li>
            Het maximum aantal certificaten wat je jaarlijks mag verkopen is
            1/3e van het maximum aantal certificaten wat je in bezit hebt gehad,
            of 75 als dat aantal minder dan 225 is (sectie 6.3).
          </li>
        </ul>
      </div>
    </main>
  );
};
