import { DetailedHTMLProps, SelectHTMLAttributes } from 'react';

export type HtmlSelectProps = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

export interface Option<T> {
  value: T;
  displayValue: string;
}

export interface Props<T> extends Omit<HtmlSelectProps, 'onChange'> {
  options: Option<T>[];
  onChange: (value: T) => void;
}

export function Select<T extends string | number = string>({
  options,
  onChange,
  ...props
}: Props<T>) {
  return (
    <select {...props} onChange={(e) => onChange(e.currentTarget.value as T)}>
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.displayValue}
        </option>
      ))}
    </select>
  );
}
