import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ButtonKind } from './Button';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '../../utils/classNames';

type AnchorProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export interface Props extends AnchorProps {
  text?: string | number;
  icon?: IconProp;
  kind?: ButtonKind;
}

export const LinkButton = ({ text, icon, kind, ...props }: Props) => (
  <a
    {...props}
    className={classNames(
      'button',
      props.className,
      icon && 'has-icon-left',
      kind
    )}
  >
    <span className="content">
      {icon && <FontAwesomeIcon className="icon" icon={icon} />}
      {text && <span className="text">{text}</span>}
    </span>
  </a>
);
