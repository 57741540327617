import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useApi } from './useApi';
import { User } from '../model/User';
import { ApiError } from '../services/ApiError';

const UserContext = React.createContext<{ user?: User } | undefined>(undefined);

export interface Props {
  children: ReactNode;
  loader: JSX.Element;
}

export const UserProvider = ({ children, loader }: Props) => {
  const api = useApi();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (!user) {
      api
        .getMe()
        .then(setUser)
        .catch((e) => {
          if (e instanceof ApiError && e.status === 401) {
            window.location.href = '/auth/login';
          } else {
            throw e;
          }
        });
    }
  }, [api, user]);

  if (!user) {
    return loader;
  }

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

export function useUser(): User | undefined {
  const data = useContext(UserContext);
  if (!data) {
    throw new Error('Cannot call useUser from outside a UserContext provider.');
  }
  return data.user;
}

export function useAssertUser(): User {
  const user = useUser();
  if (!user) {
    throw new Error('User is not logged in.');
  }
  return user;
}
