import { JsonApiService } from './JsonApiService';
import { Injector } from 'react-service-injector';
import { Page, PageParameters } from '../model/Page';
import {
  CreateTransactions,
  Transaction,
  TransactionsFilter,
} from '../model/Transaction';
import { Certificate } from '../model/Certificate';

export class TransactionsService {
  private readonly api: JsonApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(JsonApiService);
    this.listTransactions = this.listTransactions.bind(this);
  }

  public listTransactions(
    page: PageParameters,
    filter?: TransactionsFilter
  ): Promise<Page<Transaction>> {
    return this.api.jsonGet(`/api/transactions`, { ...page, ...filter });
  }

  public async getAllTransactions(
    filter: TransactionsFilter
  ): Promise<Transaction[]> {
    const result: Transaction[] = [];

    let pageNum = 1;
    let hasMore = true;

    while (hasMore) {
      const pageParams: PageParameters = { page: pageNum, limit: 1000 };
      const page = await this.api.jsonGet<Page<Transaction>>(
        `/api/transactions`,
        { ...pageParams, ...filter }
      );
      result.push(...page.data);

      hasMore = page.meta.page < page.meta.pageCount;
      pageNum++;
    }

    return result;
  }

  public createTransactions(
    transactions: CreateTransactions
  ): Promise<Certificate[]> {
    return this.api.jsonPost('/api/transactions', transactions);
  }
}
