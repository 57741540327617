import { useApi } from '../hooks/useApi';
import { useRequiredIntParam } from '../hooks/useRequiredIntParam';
import { Link } from 'react-router-dom';
import { PageLoading } from '../components/layout/PageLoading';
import { useCallback, useEffect, useState } from 'react';
import { formatDate } from '../utils/date';
import { UpdateValuation, Valuation } from '../model/Valuation';
import { useFormValue } from '../hooks/useFormValue';
import { EditableForm } from '../components/form/editable/EditableForm';
import { EditableColumns } from '../components/form/editable/EditableColumns';

export const ValuationPage = () => {
  const api = useApi();
  const valuationId = useRequiredIntParam('valuationId');

  const [valuation, setValuation] = useState<Valuation>();
  const [updatedValuation, setUpdatedField, setUpdatedValuation] =
    useFormValue<UpdateValuation>({
      date: '',
      totalValue: 0,
      notes: '',
    });

  useEffect(() => {
    api.valuations.getValuation(valuationId).then(setValuation);
  }, [api, valuationId]);

  const startEditing = useCallback(() => {
    if (!valuation) {
      return;
    }

    setUpdatedValuation({ ...valuation });
  }, [setUpdatedValuation, valuation]);

  const saveEdits = useCallback(() => {
    return api.valuations
      .updateValuation(valuationId, updatedValuation)
      .then(setValuation);
  }, [api, updatedValuation, valuationId]);

  if (!valuation) {
    return <PageLoading />;
  }

  return (
    <main className="share-page">
      <div className="header">
        <h1>
          <Link to="/valuations">Waarderingen</Link> /{' '}
          {formatDate(valuation.referenceDate)}
        </h1>
      </div>
      <EditableForm onSubmit={saveEdits} onEdit={startEditing}>
        {({ editing }) => (
          <EditableColumns
            currentValue={valuation}
            value={updatedValuation}
            editing={editing}
            setUpdatedField={setUpdatedField}
            rows={[
              {
                name: 'Datum',
                key: 'date',
                icon: 'calendar-day',
                required: true,
                type: 'date',
              },
              {
                name: 'Peildatum',
                valueFn: () => valuation.referenceDate,
                icon: 'calendar-day',
                required: true,
                type: 'date',
                editable: false,
              },
              {
                name: 'Totale waarde',
                key: 'totalValue',
                icon: 'coins',
                required: true,
                type: 'euro-whole',
              },
              {
                name: 'Notities',
                key: 'notes',
                icon: 'clipboard-list',
                type: 'textarea',
              },
            ]}
          />
        )}
      </EditableForm>
    </main>
  );
};
