import { useApi } from '../hooks/useApi';
import { PaginatedTable } from '../components/table/PaginatedTable';
import { usePagedData } from '../hooks/usePagedData';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/form/Button';
import React, { useState } from 'react';
import { CreateTradeRoundModal } from '../components/modals/CreateTradeRoundModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TradeRoundsPage = () => {
  const api = useApi();
  const tradeRoundsData = usePagedData(api.trading.listTradeRounds);
  const navigate = useNavigate();
  const [createTradeRoundModal, setCreateTradeRoundModal] = useState(false);

  return (
    <>
      <main className="trading-page">
        <div className="header">
          <h1>Handelsrondes</h1>
          <Button
            text="Nieuwe handelsronde"
            icon="plus"
            onClick={() => setCreateTradeRoundModal(true)}
            kind="primary"
          />
        </div>
        <PaginatedTable
          pagedData={tradeRoundsData}
          onClickRow={(r) => navigate(`/trade-rounds/${r.id}`)}
          columns={[
            {
              name: 'Startdatum',
              valueFn: (r) => r.startDate,
            },
            {
              name: 'Einddatum',
              valueFn: (r) => r.endDate,
            },
            {
              name: 'Aanmeldingen',
              valueFn: (r) => r.registrationsCount,
            },
          ]}
          actions={(r) =>
            r.transactionsCreated && <FontAwesomeIcon icon="lock" />
          }
        />
      </main>
      {createTradeRoundModal && (
        <CreateTradeRoundModal
          onClose={() => setCreateTradeRoundModal(false)}
          onSubmit={(r) => navigate(`/trade-rounds/${r.id}`)}
        />
      )}
    </>
  );
};
