import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const PageLoading = () => (
  <main className="page-loading">
    <h1>
      <FontAwesomeIcon icon="spinner" pulse className="spinner" />
    </h1>
  </main>
);
