import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { TransactionsPage } from './pages/TransactionsPage';
import { MembersPage } from './pages/MembersPage';
import { MemberPage } from './pages/MemberPage';
import { CertificatesPage } from './pages/CertificatesPage';
import { SharesPage } from './pages/SharesPage';
import { SharePage } from './pages/SharePage';
import { ValuationsPage } from './pages/ValuationsPage';
import { ValuationPage } from './pages/ValuationPage';
import { DividendsPage } from './pages/DividendsPage';
import { DividendPage } from './pages/DividendPage';
import { CurrentTradeRoundPage } from './pages/CurrentTradeRoundPage';
import { TradeRoundsPage } from './pages/TradeRoundsPage';
import { TradeRoundPage } from './pages/TradeRoundPage';
import { AdminsPage } from './pages/AdminsPage';
import React from 'react';
import { Layout } from './components/layout/Layout';
import { OverviewPage } from './pages/OverviewPage';
import { IncomeTaxPage } from './pages/IncomeTaxPage';
import { ChecksPage } from './pages/ChecksPage';

export const Router = () => <RouterProvider router={router} />;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Navigate to="/" replace />,
    children: [
      {
        path: '',
        element: <OverviewPage />,
      },
      {
        path: 'transactions',
        element: <TransactionsPage />,
      },
      {
        path: 'members',
        element: <MembersPage />,
      },
      {
        path: 'members/:memberId',
        element: <MemberPage />,
      },
      {
        path: 'certificates',
        element: <CertificatesPage />,
      },
      {
        path: 'shares',
        element: <SharesPage />,
      },
      {
        path: 'shares/:shareId',
        element: <SharePage />,
      },
      {
        path: 'valuations',
        element: <ValuationsPage />,
      },
      {
        path: 'valuations/:valuationId',
        element: <ValuationPage />,
      },
      {
        path: 'dividends',
        element: <DividendsPage />,
      },
      {
        path: 'dividends/:dividendId',
        element: <DividendPage />,
      },
      {
        path: 'trade-round',
        element: <CurrentTradeRoundPage />,
      },
      {
        path: 'trade-rounds',
        element: <TradeRoundsPage />,
      },
      {
        path: 'trade-rounds/:tradeRoundId',
        element: <TradeRoundPage />,
      },
      {
        path: 'income-tax',
        element: <IncomeTaxPage />,
      },
      {
        path: 'checks',
        element: <ChecksPage />,
      },
      {
        path: 'admins',
        element: <AdminsPage />,
      },
    ],
  },
]);
