import { HtmlInputProps } from './Input';

export type TextInputType = 'text' | 'email' | 'date';

export interface Props extends Omit<HtmlInputProps, 'onChange' | 'type'> {
  value: string | undefined;
  onChange: (value: string) => void;
  type?: TextInputType;
}

export const TextInput = ({ value, onChange, ...props }: Props) => (
  <span className="input">
    <input
      {...props}
      value={value ?? ''}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  </span>
);

export function isTextInputType(type: string): type is TextInputType {
  return type === 'text' || type === 'email' || type === 'date';
}
