import { ApiError, asApiErrorResponse } from './ApiError';

type QueryParams = Record<string, string | number | boolean>;

export class JsonApiService {
  private readonly defaultHeaders: Record<string, string>;

  public constructor() {
    this.defaultHeaders = {
      Accept: 'application/json',
    };
  }

  private static async handleResponse<T>(
    resPromise: Promise<Response>
  ): Promise<T> {
    const response = await resPromise;
    const responseBody =
      response.status === 204 ? undefined : await response.json();

    if (response.status >= 400) {
      const errBody = asApiErrorResponse(responseBody);
      throw new ApiError(response.status, errBody.message, errBody.errors);
    }

    return responseBody;
  }

  private static getUrl(path: string, params?: QueryParams): string {
    const stringParams: Record<string, string> = {};
    if (params) {
      Object.entries(params).forEach(([k, v]) => {
        if (v != null) {
          stringParams[k] = `${v}`;
        }
      });
    }

    const paramString = params ? `?${new URLSearchParams(stringParams)}` : '';
    return `${path}${paramString}`;
  }

  private jsonRequest<T>(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    path: string,
    body?: unknown,
    params?: QueryParams
  ): Promise<T> {
    const headers = this.defaultHeaders;
    if (body) {
      headers['Content-Type'] = 'application/json';
    }

    return JsonApiService.handleResponse(
      fetch(JsonApiService.getUrl(path, params), {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      })
    );
  }

  public jsonGet<T>(path: string, params?: QueryParams): Promise<T> {
    return this.jsonRequest('GET', path, undefined, params);
  }

  public jsonPost<T, S>(
    path: string,
    body: T,
    params?: QueryParams
  ): Promise<S> {
    return this.jsonRequest<S>('POST', path, body, params);
  }

  public jsonPut<T, S>(
    path: string,
    body: T,
    params?: QueryParams
  ): Promise<S> {
    return this.jsonRequest<S>('PUT', path, body, params);
  }

  public jsonDelete<S>(path: string, params?: QueryParams): Promise<S> {
    return this.jsonRequest<S>('DELETE', path, undefined, params);
  }
}
