import { useApi } from '../../hooks/useApi';
import { CloseSubmitProps, Modal } from '../Modal';
import { Form } from '../form/Form';
import { CreateValuation } from '../../model/Valuation';
import { useFormValue } from '../../hooks/useFormValue';
import { today } from '../../utils/date';
import { Input } from '../form/Input';

export const CreateValuationModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps) => {
  const api = useApi();
  const [valuation, setField] = useFormValue<CreateValuation>(() => ({
    date: today(),
    totalValue: 0,
  }));

  return (
    <Modal title="Nieuwe waardering" onClose={onClose}>
      <Form
        className="rows"
        submitLabel="Opslaan"
        onSubmit={() =>
          api.valuations.createValuation(valuation).then(() => {
            onSubmit();
            onClose();
          })
        }
      >
        <div>
          <label>Datum</label>
          <Input
            value={valuation.date}
            onChange={setField('date')}
            required
            type="date"
          />
        </div>
        <div>
          <label>Totale waarde (euro's)</label>
          <Input
            value={valuation.totalValue}
            onChange={setField('totalValue')}
            required
            min={0}
            type="euro-whole"
          />
        </div>
      </Form>
    </Modal>
  );
};
