import { ReactNode } from 'react';
import { classNames } from '../utils/classNames';

export interface Props {
  value: number | string | undefined;
  label: string;
}

export const StatCard = ({ value, label }: Props) => (
  <div className="stat-card">
    <div className="value">{value ?? '-'}</div>
    <div>{label}</div>
  </div>
);

export interface StatCardsProps {
  children: ReactNode;
  colored?: boolean;
}

export const StatCards = ({ children, colored }: StatCardsProps) => (
  <div className={classNames('stat-cards', colored && 'colored')}>
    {children}
  </div>
);
