import React, { useState } from 'react';
import { useApi } from '../hooks/useApi';
import { Button } from '../components/form/Button';
import { formatWholeEuros } from '../utils/currency';
import { CreateDividendModal } from '../components/modals/CreateDividendModal';
import { PaginatedTable } from '../components/table/PaginatedTable';
import { usePagedData } from '../hooks/usePagedData';
import { formatDate } from '../utils/date';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPercentWhole } from '../utils/percent';

export const DividendsPage = () => {
  const api = useApi();
  const dividendsData = usePagedData(api.dividends.listDividends);
  const [createDividendModal, setCreateDividendModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <main className="dividends-page">
        <div className="header">
          <h1>Dividend</h1>
          <Button
            text="Nieuwe dividenduitkering"
            icon="plus"
            onClick={() => setCreateDividendModal(true)}
            kind="primary"
          />
        </div>
        <PaginatedTable
          pagedData={dividendsData}
          onClickRow={(d) => navigate(`/dividends/${d.id}`)}
          columns={[
            {
              name: 'Peildatum',
              valueFn: (d) => formatDate(d.date),
            },
            {
              name: 'Waarde',
              valueFn: (d) => formatWholeEuros(d.amount),
            },
            {
              name: 'Belasting',
              valueFn: (d) => formatPercentWhole(d.taxPercent),
            },
          ]}
          actions={(d) => d.locked && <FontAwesomeIcon icon="lock" />}
        />
      </main>
      {createDividendModal && (
        <CreateDividendModal
          onClose={() => setCreateDividendModal(false)}
          onSubmit={(d) => navigate(`/dividends/${d.id}`)}
        />
      )}
    </>
  );
};
