import { Switch } from '@headlessui/react';
import { classNames } from '../../utils/classNames';

export interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export const Toggle = ({ checked, onChange, label }: Props) => {
  return (
    <div className="toggle">
      <Switch
        checked={checked}
        onChange={onChange}
        className={classNames('switch', checked && 'checked')}
      >
        <span className="circle" />
      </Switch>
      <label>{label}</label>
    </div>
  );
};
