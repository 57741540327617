import { Button } from './form/Button';
import { ButtonGroup } from './form/ButtonGroup';
import { Page } from '../model/Page';

export interface Props {
  page: Page<unknown>;
  onNavigate: (page: number) => void;
}

export const Pagination = ({ page, onNavigate }: Props) => {
  let buttons = range(1, page.meta.pageCount).map((p) => (
    <Button
      key={p}
      disabled={page.meta.page === p}
      onClick={() => onNavigate(p)}
      text={p}
    />
  ));

  const startCounting = Math.min(page.meta.page - 1, page.meta.pageCount - 4);
  let removed = 0;
  if (startCounting >= 4) {
    buttons = [
      buttons[0],
      <Ellipsis key="ellipsis-left" />,
      ...buttons.slice(startCounting - 1),
    ];
    removed = startCounting - 3;
  }
  const stopCounting = Math.max(page.meta.page + 1, 5);
  if (buttons.length > 7) {
    buttons = [
      ...buttons.slice(0, stopCounting - removed),
      <Ellipsis key="ellipsis-right" />,
      buttons[buttons.length - 1],
    ];
  }

  return (
    <div className="pagination">
      <div>
        {page.meta.totalElements > 0
          ? `Toont ${page.meta.offset + 1} tot ${
              page.meta.offset + page.data.length
            } van ${page.meta.totalElements} items`
          : 'Geen data'}
      </div>
      <ButtonGroup>
        <Button
          disabled={page.meta.page === 1}
          onClick={() => onNavigate(page.meta.page - 1)}
          icon="angle-left"
        />
        {buttons}
        <Button
          disabled={page.meta.page >= page.meta.pageCount}
          onClick={() => onNavigate(page.meta.page + 1)}
          icon="angle-right"
        />
      </ButtonGroup>
    </div>
  );
};

const range = (min: number, max: number): number[] => {
  if (max < min) {
    return [];
  }

  return Array(max - min + 1)
    .fill(null)
    .map((_, i) => i + min);
};

const Ellipsis = () => <Button className="ellipsis" icon="ellipsis" disabled />;
