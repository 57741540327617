import { useRequiredParam } from './useRequiredParam';

export function useRequiredIntParam(name: string): number {
  const param = useRequiredParam(name);
  const num = parseInt(param, 10);
  if (isNaN(num)) {
    throw new Error(`Parameter is not an integer: ${name}`);
  }
  return num;
}
