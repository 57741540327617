export function today(): string {
  return new Date().toISOString().substring(0, 10);
}

export function startOfYear(date: string): string {
  return `${date.substring(0, 4)}-01-01`;
}

export function formatDate(date?: string): string {
  if (!date) {
    return '-';
  }

  return Intl.DateTimeFormat('nl-NL', {
    dateStyle: 'long',
  }).format(new Date(date));
}
