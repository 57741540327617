import { HtmlInputProps } from './Input';
import { useEffect, useState } from 'react';

export type NumberInputType = 'number' | CurrencyInputType | 'percent';

export type CurrencyInputType = 'euro' | 'euro-whole';

export interface Props
  extends Omit<HtmlInputProps, 'onChange' | 'type' | 'value'> {
  value: number;
  onChange: (value: number) => void;
  type?: NumberInputType;
}

export const NumberInput = ({ onChange, type, ...props }: Props) => {
  // The current, raw value.
  // Note that for currency types, this will be the value in cents.
  const [rawValue, setRawValue] = useState(() =>
    getRawValue(props.value, type)
  );

  useEffect(() => {
    setRawValue(getRawValue(props.value, type));
  }, [props.value, type]);

  return (
    <span className="input">
      <input
        {...props}
        type="number"
        value={rawValue}
        onChange={(e) => {
          const newValue = e.currentTarget.value;
          setRawValue(newValue);

          let newValueNum = parseFloat(newValue);

          // For currency types, make sure the onChange handler receives the value in cents.
          if (isCurrencyType(type)) {
            newValueNum *= 100;
          }

          onChange(newValueNum);
        }}
        step={isCurrencyType(type) ? stepForCurrencyType(type) : props.step}
      />
    </span>
  );
};

function getRawValue(value: number, type?: NumberInputType): string {
  return String(isCurrencyType(type) ? value / 100 : value);
}

function isCurrencyType(type?: NumberInputType): type is CurrencyInputType {
  return type === 'euro' || type === 'euro-whole';
}

function stepForCurrencyType(type: CurrencyInputType): number {
  if (type === 'euro') {
    return 0.01;
  }
  return 1;
}
