import { Member } from '../model/Member';
import { Link } from 'react-router-dom';

export interface Props {
  member: Member;
}

export const MemberLink = ({ member }: Props) => (
  <Link to={`/members/${member.id}`}>{member.name}</Link>
);
