import { PagedData } from '../../hooks/usePagedData';
import { formatDate } from '../../utils/date';
import { PaginatedTable } from '../table/PaginatedTable';
import {
  Transaction,
  TransactionGroup,
  transactionTypeNames,
} from '../../model/Transaction';
import { useAssertUser } from '../../hooks/useUser';
import { LinkButton } from '../form/LinkButton';
import { formatEuro } from '../../utils/currency';
import { MemberLink } from '../MemberLink';
import { useState } from 'react';
import { TransactionCertificatesModal } from '../modals/TransactionCertificatesModal';
import { ButtonLink } from '../ButtonLink';

export interface Props {
  transactions: PagedData<Transaction | TransactionGroup>;
  memberId?: number;
  group?: boolean;
}

export const TransactionsTable = ({ transactions, memberId, group }: Props) => {
  const user = useAssertUser();
  const [selectedGroup, setSelectedGroup] = useState<TransactionGroup>();

  return (
    <>
      <PaginatedTable
        pagedData={transactions}
        columns={[
          {
            name: 'Datum',
            valueFn: (t) => formatDate(t.date),
          },
          !memberId && {
            name: 'Deelnemer',
            valueFn: (t) => <MemberLink member={t.member} />,
          },
          {
            name: 'Type',
            valueFn: (t) => transactionTypeNames[t.type],
          },
          group
            ? {
                name: 'Certificaten',
                valueFn: (t) => (
                  <ButtonLink
                    onClick={() => setSelectedGroup(t as TransactionGroup)}
                    text={(t as TransactionGroup).certificatesCount || '-'}
                  />
                ),
              }
            : {
                name: 'Certificaat',
                valueFn: (t) => t.certificate?.fullName,
              },
          {
            name: 'Prijs',
            valueFn: (t) => formatEuro(t.price),
          },
        ]}
        actions={
          user.isAdmin &&
          group &&
          ((t) => (
            <LinkButton
              href={`/api/transactions/pdf?type=${t.type}&memberId=${t.member.id}&date=${t.date}`}
              download={`${t.member.id}-${t.type}-${t.date}.pdf`}
              icon="file-pdf"
              text="Download PDF"
            />
          ))
        }
      />
      {selectedGroup && (
        <TransactionCertificatesModal
          group={selectedGroup}
          onClose={() => setSelectedGroup(undefined)}
        />
      )}
    </>
  );
};
