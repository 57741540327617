import { Input } from '../Input';
import { Props as TextInputProps } from '../TextInput';
import { NumberInputType, Props as NumberInputProps } from '../NumberInput';
import { DisplayValue } from './DisplayValue';

export type Props = (EditableTextInputProps | EditableNumberInputProps) & {
  editing: boolean;
};

type EditableTextInputProps = TextInputProps & {
  currentValue: TextInputProps['value'];
};

type EditableNumberInputProps = NumberInputProps & {
  type: NumberInputType;
  currentValue: NumberInputProps['value'];
};

export const EditableInput = ({ editing, currentValue, ...props }: Props) =>
  editing ? (
    <Input {...props} />
  ) : (
    // Here we use `as never` for the value,
    // because TS thinks the type and value can be a wrong combination,
    // e.g. type text and a number value.
    // However, that is not possible with the props of this component.
    <DisplayValue type={props.type} value={currentValue as never} />
  );
