import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CertsInTime } from '../../model/Stats';
import { formatDate } from '../../utils/date';
import { toTwoDigitsNumber } from '../../utils/percent';
import { splineSeries } from './series';

export interface Props {
  certsOverTime: CertsInTime[];
}

export const CertificatesChart = ({ certsOverTime }: Props) => {
  const options: Highcharts.Options = {
    title: {
      text: 'Certificaten en belang over tijd',
    },
    series: [
      {
        ...splineSeries(0),
        name: 'Toegewezen certificaten',
        data: certsOverTime.map((c) => c.assignedCerts),
        yAxis: 0,
      },
      {
        ...splineSeries(1),
        name: 'Totale belang',
        data: certsOverTime.map((c) => toTwoDigitsNumber(c.employeeOwnedRatio)),
        yAxis: 1,
        tooltip: {
          valueSuffix: '%',
        },
      },
    ],
    xAxis: {
      categories: certsOverTime.map((v) => formatDate(v.date)),
    },
    yAxis: [
      {
        title: {
          text: 'Toegewezen certificaten',
        },
      },
      {
        title: {
          text: 'Totale belang',
        },
        labels: {
          format: '{value}%',
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
  };

  return (
    <div className="chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
