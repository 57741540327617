import { TransactionsTable } from '../components/tables/TransactionsTable';
import { useApi } from '../hooks/useApi';
import { usePagedData } from '../hooks/usePagedData';
import { useState } from 'react';
import { Toggle } from '../components/form/Toggle';
import { useAssertUser } from '../hooks/useUser';

export const TransactionsPage = () => {
  const api = useApi();
  const user = useAssertUser();
  const [group, setGroup] = useState(true);
  const transactions = usePagedData(api.transactions.listTransactions, {
    group,
  });

  return (
    <main className="transactions-page">
      <div className="header">
        <h1>Transacties</h1>
      </div>
      <div className="filters">
        <Toggle checked={group} onChange={setGroup} label="Groeperen" />
      </div>
      <TransactionsTable
        transactions={transactions}
        group={group}
        memberId={user.isAdmin ? undefined : user.memberId}
      />
    </main>
  );
};
