import { ReactNode } from 'react';
import { TitleBlock } from './TitleBlock';

export interface Props {
  children: ReactNode;
}

export const ActionsBlock = ({ children }: Props) => (
  <TitleBlock className="actions-block" title="Acties" icon="square-check">
    <div className="buttons">{children}</div>
  </TitleBlock>
);
