import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink } from 'react-router-dom';
import { useAssertUser } from '../../hooks/useUser';
import { classNames } from '../../utils/classNames';

export interface Props {
  visible: boolean;
  onClose: () => void;
  checksCount: number;
}

export const Menu = ({ visible, onClose, checksCount }: Props) => (
  <nav className={classNames('menu', visible && 'visible')}>
    <Link to="/" className="app-name">
      <FontAwesomeIcon icon="shield-cat" />
      CATS
    </Link>
    <MenuItem
      icon="chart-simple"
      label="Overzicht"
      link="/"
      onClose={onClose}
    />
    <MenuItem
      adminOnly
      icon="users"
      label="Deelnemers"
      link="/members"
      onClose={onClose}
    />
    <MenuItem
      memberOrAdminOnly
      icon="handshake"
      label="Handelsronde"
      link="/trade-round"
      onClose={onClose}
    />
    <MenuItem
      adminOnly
      icon="handshake"
      label="Handelsrondes"
      link="/trade-rounds"
      onClose={onClose}
    />
    <MenuItem
      memberOrAdminOnly
      icon="right-left"
      label="Transacties"
      link="/transactions"
      onClose={onClose}
    />
    <MenuItem
      memberOrAdminOnly
      icon="certificate"
      label="Certificaten"
      link="/certificates"
      onClose={onClose}
    />
    <MenuItem
      adminOnly
      icon="file-contract"
      label="Aandelen"
      link="/shares"
      onClose={onClose}
    />
    <MenuItem
      adminOnly
      icon="coins"
      label="Waarderingen"
      link="/valuations"
      onClose={onClose}
    />
    <MenuItem
      adminOnly
      icon="percent"
      label="Dividend"
      link="/dividends"
      onClose={onClose}
    />
    <MenuItem
      memberOrAdminOnly
      icon="file-invoice-dollar"
      label="Belastingaangifte"
      link="/income-tax"
      onClose={onClose}
    />
    <div className="spacer" />
    <MenuItem
      adminOnly
      icon="check"
      label="Checks"
      link="/checks"
      onClose={onClose}
      bubbleCounter={checksCount}
    />
    <MenuItem
      adminOnly
      icon="user-shield"
      label="Admins"
      link="/admins"
      onClose={onClose}
    />
    <a className="menu-item" href="/auth/logout">
      <FontAwesomeIcon icon="right-from-bracket" />
      Uitloggen
    </a>
  </nav>
);

interface MenuItemProps {
  icon: IconProp;
  label: string;
  link: string;
  adminOnly?: boolean;
  memberOrAdminOnly?: boolean;
  onClose: () => void;
  bubbleCounter?: number;
}

const MenuItem = ({
  icon,
  label,
  link,
  adminOnly,
  memberOrAdminOnly,
  onClose,
  bubbleCounter,
}: MenuItemProps) => {
  const user = useAssertUser();

  if (adminOnly && !user.isAdmin) {
    return null;
  }

  if (memberOrAdminOnly && !user.memberId && !user.isAdmin) {
    return null;
  }

  return (
    <NavLink to={link} className="menu-item" onClick={onClose}>
      <FontAwesomeIcon icon={icon} />
      {label}
      {!!bubbleCounter && <span className="bubble">{bubbleCounter}</span>}
    </NavLink>
  );
};
