import { useApi } from '../hooks/useApi';
import { PaginatedTable } from '../components/table/PaginatedTable';
import { usePagedData } from '../hooks/usePagedData';
import { Button } from '../components/form/Button';
import { useState } from 'react';
import { CreateAdminModal } from '../components/modals/CreateAdminModal';
import { useAssertUser } from '../hooks/useUser';
import { Email } from '../components/Email';

export const AdminsPage = () => {
  const api = useApi();
  const adminsData = usePagedData(api.admins.listAdmins);
  const [createAdminModal, setCreateAdminModal] = useState(false);
  const user = useAssertUser();

  return (
    <>
      <main className="admins-page">
        <div className="header">
          <h1>Admins</h1>
          <Button
            text="Nieuwe admin"
            icon="plus"
            onClick={() => setCreateAdminModal(true)}
            kind="primary"
          />
        </div>
        <PaginatedTable
          pagedData={adminsData}
          columns={[
            {
              name: 'E-mail',
              valueFn: (a) => <Email value={a.email} />,
            },
          ]}
          actions={(a) =>
            a.email === user.email ? undefined : (
              <Button
                icon="trash"
                onClick={async () => {
                  await api.admins.deleteAdmin(a.id);
                  adminsData.reloadPage();
                }}
              />
            )
          }
        />
      </main>
      {createAdminModal && (
        <CreateAdminModal
          onClose={() => setCreateAdminModal(false)}
          onSubmit={() => adminsData.reloadPage()}
        />
      )}
    </>
  );
};
