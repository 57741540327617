import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ValueInTime } from '../../model/Stats';
import { splineSeries } from './series';

export interface Props {
  valueOverTime: ValueInTime[];
}

export const ValueChart = ({ valueOverTime }: Props) => {
  const options: Highcharts.Options = {
    title: {
      text: 'Waarde over tijd',
    },
    series: [
      {
        ...splineSeries(0),
        name: 'Certificaatwaarde',
        data: valueOverTime.map((v) => v.certValue / 100),
        yAxis: 0,
        tooltip: {
          valuePrefix: '€ ',
          valueDecimals: 2,
        },
      },
      {
        ...splineSeries(1),
        name: 'Totale waarde',
        data: valueOverTime.map((v) => v.totalValue / 100),
        yAxis: 1,
        tooltip: {
          valuePrefix: '€ ',
        },
      },
    ],
    xAxis: {
      categories: valueOverTime.map((v) => v.year),
    },
    yAxis: [
      {
        title: {
          text: 'Certificaatwaarde',
        },
        labels: {
          format: '€ {value}',
        },
      },
      {
        title: {
          text: 'Totale waarde',
        },
        labels: {
          format: '€ {value}',
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
  };

  return (
    <div className="chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
