import { JsonApiService } from './JsonApiService';
import { User } from '../model/User';
import { Injector } from 'react-service-injector';
import { MembersService } from './MembersService';
import { TransactionsService } from './TransactionsService';
import { SharesService } from './SharesService';
import { CertificatesService } from './CertificatesService';
import { ValuationsService } from './ValuationsService';
import { AdminsService } from './AdminsService';
import { DividendsService } from './DividendsService';
import { TradeRoundsService } from './TradeRoundsService';
import { StatsService } from './StatsService';
import { IncomeTaxService } from './IncomeTaxService';
import { ChecksService } from './ChecksService';

export class CatsApiService {
  public readonly admins: AdminsService;
  public readonly certificates: CertificatesService;
  public readonly checks: ChecksService;
  public readonly dividends: DividendsService;
  public readonly incomeTax: IncomeTaxService;
  public readonly members: MembersService;
  public readonly shares: SharesService;
  public readonly stats: StatsService;
  public readonly trading: TradeRoundsService;
  public readonly transactions: TransactionsService;
  public readonly valuations: ValuationsService;

  private readonly api: JsonApiService;

  public constructor(injector: Injector) {
    this.admins = injector.resolve(AdminsService);
    this.certificates = injector.resolve(CertificatesService);
    this.checks = injector.resolve(ChecksService);
    this.dividends = injector.resolve(DividendsService);
    this.incomeTax = injector.resolve(IncomeTaxService);
    this.members = injector.resolve(MembersService);
    this.shares = injector.resolve(SharesService);
    this.stats = injector.resolve(StatsService);
    this.trading = injector.resolve(TradeRoundsService);
    this.transactions = injector.resolve(TransactionsService);
    this.valuations = injector.resolve(ValuationsService);

    this.api = injector.resolve(JsonApiService);
  }

  public getMe(): Promise<User> {
    return this.api.jsonGet('/api/auth/me');
  }
}
