import { useRequiredIntParam } from '../hooks/useRequiredIntParam';
import { Link } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { TradeRoundDetails } from '../model/TradeRound';
import { PageLoading } from '../components/layout/PageLoading';
import { StatCard, StatCards } from '../components/StatCard';
import { ItemRows } from '../components/ItemRows';
import { formatDate, today } from '../utils/date';
import { Table } from '../components/table/Table';
import { MemberLink } from '../components/MemberLink';
import { transactionTypeNames } from '../model/Transaction';
import { ActionsBlock } from '../components/ActionsBlock';
import { Button } from '../components/form/Button';
import { LinkButton } from '../components/form/LinkButton';
import { InlineEditableInput } from '../components/form/InlineEditableInput';

export const TradeRoundPage = () => {
  const api = useApi();
  const tradeRoundId = useRequiredIntParam('tradeRoundId');

  const [tradeRound, setTradeRound] = useState<TradeRoundDetails>();

  const refresh = useCallback(() => {
    api.trading.getTradeRound(tradeRoundId).then(setTradeRound);
  }, [api, tradeRoundId]);

  useEffect(refresh, [refresh]);

  if (!tradeRound) {
    return <PageLoading />;
  }

  return (
    <main className="trade-round-page">
      <div className="header">
        <div>
          <h1>
            <Link to="/trade-rounds">Handelsrondes</Link> /{' '}
            {tradeRound.startDate}
          </h1>
        </div>
      </div>
      <div className="info-columns">
        <ItemRows
          data={[
            {
              key: 'Startdatum',
              value: formatDate(tradeRound.startDate),
              icon: 'calendar-day',
            },
            {
              key: 'Einddatum',
              value: formatDate(tradeRound.endDate),
              icon: 'calendar-xmark',
            },
          ]}
        />
        {!tradeRound.transactionsCreated && tradeRound.endDate < today() && (
          <ActionsBlock>
            <Button
              text="Transacties aanmaken"
              icon="right-left"
              kind="primary"
              onClick={() =>
                api.trading
                  .createTransactionsForTradeRound(tradeRoundId)
                  .then(() => api.trading.getTradeRound(tradeRoundId))
                  .then(setTradeRound)
              }
            />
          </ActionsBlock>
        )}
      </div>
      <div className="section">
        <StatCards>
          <StatCard
            value={tradeRound.registrations.length}
            label="Aanmeldingen"
          />
          <StatCard
            value={tradeRound.totalBuy}
            label="Totale koop (verzocht)"
          />
          <StatCard
            value={tradeRound.totalBuyActual}
            label="Totale koop (daadwerkelijk)"
          />
          <StatCard
            value={tradeRound.totalSell}
            label="Totale verkoop (verzocht)"
          />
          <StatCard
            value={tradeRound.totalSellActual}
            label="Totale verkoop (daadwerkelijk)"
          />
        </StatCards>
      </div>
      <div className="sub-header">
        <h2>Aanmeldingen</h2>
      </div>
      <Table
        data={tradeRound.registrations}
        columns={[
          {
            name: 'Deelnemer',
            valueFn: (r) => <MemberLink member={r.member} />,
          },
          {
            name: 'Type',
            valueFn: (r) => transactionTypeNames[r.type],
          },
          {
            name: 'Aantal (verzocht)',
            valueFn: (r) => r.requestedAmount,
          },
          {
            name: 'Aantal (daadwerkelijk)',
            valueFn: (r) =>
              tradeRound.transactionsCreated ? (
                r.actualAmount
              ) : (
                <InlineEditableInput
                  value={r.actualAmount}
                  type="number"
                  min={0}
                  max={r.requestedAmount}
                  onChange={async (newActual) => {
                    await api.trading.updateTradeRoundRegistration(
                      tradeRound.id,
                      r.id,
                      { actualAmount: newActual }
                    );
                    refresh();
                  }}
                />
              ),
          },
        ]}
        actions={(r) => (
          <LinkButton
            href={`/api/trade-rounds/${tradeRound.id}/registrations/${r.id}/partition-letter`}
            download={`Verdelingsbrief-${tradeRound.id}-${r.member.id}.pdf`}
            icon="file-pdf"
            text="Verdelingsbrief"
          />
        )}
      />
    </main>
  );
};
