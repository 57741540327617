import { Form } from '../form/Form';
import { CloseSubmitProps, Modal } from '../Modal';
import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { CreateMember, Member } from '../../model/Member';
import { Input } from '../form/Input';

export const CreateMemberModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps<Member>) => {
  const api = useApi();
  const [member, setField] = useFormValue<CreateMember>({
    name: '',
    email: '',
  });

  return (
    <Modal title="Nieuwe deelnemer" onClose={onClose}>
      <Form
        className="rows"
        submitLabel="Opslaan"
        onSubmit={() =>
          api.members.createMember(member).then((m) => {
            onSubmit(m);
            onClose();
          })
        }
      >
        <div>
          <label>Naam</label>
          <Input value={member.name} onChange={setField('name')} required />
        </div>
        <div>
          <label>E-mail</label>
          <Input
            value={member.email}
            onChange={setField('email')}
            required
            type="email"
          />
        </div>
        <div>
          <label>Startdatum</label>
          <Input
            value={member.startDate}
            onChange={setField('startDate')}
            type="date"
          />
        </div>
      </Form>
    </Modal>
  );
};
