import { Form } from '../form/Form';
import { CloseSubmitProps, Modal } from '../Modal';
import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { today } from '../../utils/date';
import { Input } from '../form/Input';
import { CreateTradeRound, TradeRoundDetails } from '../../model/TradeRound';

export const CreateTradeRoundModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps<TradeRoundDetails>) => {
  const api = useApi();
  const [tradeRound, setField] = useFormValue<CreateTradeRound>(() => ({
    startDate: today(),
    endDate: today(),
  }));

  return (
    <Modal title="Nieuwe handelsronde" onClose={onClose}>
      <Form
        className="rows"
        submitLabel="Opslaan"
        onSubmit={() =>
          api.trading.createTradeRound(tradeRound).then((r) => {
            onSubmit(r);
            onClose();
          })
        }
      >
        <div>
          <label>Startdatum</label>
          <Input
            type="date"
            value={tradeRound.startDate}
            onChange={setField('startDate')}
            required
          />
        </div>
        <div>
          <label>Einddatum</label>
          <Input
            type="date"
            value={tradeRound.endDate}
            onChange={setField('endDate')}
            required
          />
        </div>
      </Form>
    </Modal>
  );
};
