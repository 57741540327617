import { JsonApiService } from './JsonApiService';
import { Injector } from 'react-service-injector';
import {
  CreateDividend,
  Dividend,
  DividendDetails,
  UpdateDividend,
} from '../model/Dividends';
import { Page, PageParameters } from '../model/Page';

export class DividendsService {
  private readonly api: JsonApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(JsonApiService);
    this.listDividends = this.listDividends.bind(this);
  }

  public listDividends(page: PageParameters): Promise<Page<Dividend>> {
    return this.api.jsonGet('/api/dividends', { ...page });
  }

  public getDividend(id: number): Promise<Dividend> {
    return this.api.jsonGet(`/api/dividends/${id}`);
  }

  public createDividend(dividend: CreateDividend): Promise<Dividend> {
    return this.api.jsonPost('/api/dividends', dividend);
  }

  public updateDividend(
    id: number,
    updates: UpdateDividend
  ): Promise<Dividend> {
    return this.api.jsonPut(`/api/dividends/${id}`, updates);
  }

  public lockDividend(id: number): Promise<void> {
    return this.api.jsonPost(`/api/dividends/${id}/lock`, {});
  }

  public getDividendDetails(id: number): Promise<DividendDetails> {
    return this.api.jsonGet(`/api/dividends/${id}/details`);
  }
}
