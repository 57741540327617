import { CloseSubmitProps, Modal } from '../Modal';
import { CreateDividend, Dividend } from '../../model/Dividends';
import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { today } from '../../utils/date';
import { Form } from '../form/Form';
import { Input } from '../form/Input';

export const CreateDividendModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps<Dividend>) => {
  const api = useApi();
  const [dividend, setField] = useFormValue<CreateDividend>(() => ({
    date: today(),
    amount: 0,
  }));

  return (
    <Modal title="Nieuwe dividenduitkering" onClose={onClose}>
      <Form
        className="rows"
        submitLabel="Opslaan"
        onSubmit={() =>
          api.dividends.createDividend(dividend).then((d) => {
            onSubmit(d);
            onClose();
          })
        }
      >
        <div>
          <label>Peildatum</label>
          <Input
            value={dividend.date}
            onChange={setField('date')}
            type="date"
            required
          />
        </div>
        <div>
          <label>Waarde (euro's)</label>
          <Input
            value={dividend.amount}
            onChange={setField('amount')}
            type="euro-whole"
            required
          />
        </div>
      </Form>
    </Modal>
  );
};
