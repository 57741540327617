import { JsonApiService } from './JsonApiService';
import { Injector } from 'react-service-injector';
import { Page, PageParameters } from '../model/Page';
import { Admin, CreateAdmin } from '../model/Admin';

export class AdminsService {
  private readonly api: JsonApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(JsonApiService);
    this.listAdmins = this.listAdmins.bind(this);
  }

  public listAdmins(page: PageParameters): Promise<Page<Admin>> {
    return this.api.jsonGet('/api/auth/admins', { ...page });
  }

  public createAdmin(admin: CreateAdmin): Promise<void> {
    return this.api.jsonPost('/api/auth/admins', admin);
  }

  public deleteAdmin(id: number): Promise<void> {
    return this.api.jsonDelete(`/api/auth/admins/${id}`);
  }
}
