import { VolumeInTime } from '../../model/Stats';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { splineSeries } from './series';

export interface Props {
  tradeVolumeOverTime: VolumeInTime[];
}

export const TradeVolumeChart = ({ tradeVolumeOverTime }: Props) => {
  const options: Options = {
    title: {
      text: 'Handelsvolume per jaar',
    },
    xAxis: {
      categories: tradeVolumeOverTime.map((v) => v.year),
    },
    yAxis: {
      title: {
        text: 'Aantal certificaten',
      },
      min: 0,
    },
    series: [
      {
        ...splineSeries(0),
        name: 'Verkoopvolume',
        data: tradeVolumeOverTime.map((v) => v.sellVolume),
      },
      {
        ...splineSeries(1),
        name: 'Aankoopvolume',
        data: tradeVolumeOverTime.map((v) => v.buyVolume),
      },
    ],
    tooltip: {
      shared: true,
    },
  };

  return (
    <div className="chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
