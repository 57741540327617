import { Form } from '../form/Form';
import { CloseSubmitProps, Modal } from '../Modal';
import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { CreateShare, Share } from '../../model/Share';
import { today } from '../../utils/date';
import { Input } from '../form/Input';

export const CreateShareModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps<Share>) => {
  const api = useApi();
  const [shares, setField] = useFormValue<CreateShare>(() => ({
    name: '',
    acquireDate: today(),
  }));

  return (
    <Modal title="Nieuw aandeel" onClose={onClose}>
      <Form
        className="rows"
        submitLabel="Opslaan"
        onSubmit={() =>
          api.shares.createShare(shares).then((s) => {
            onSubmit(s);
            onClose();
          })
        }
      >
        <div>
          <label>Naam</label>
          <Input value={shares.name} onChange={setField('name')} required />
        </div>
        <div>
          <label>Verkregen</label>
          <Input
            type="date"
            value={shares.acquireDate}
            onChange={setField('acquireDate')}
            required
          />
        </div>
      </Form>
    </Modal>
  );
};
