import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Props {
  children: ReactNode;
  title: string;
  onClose: () => void;
}

export interface CloseProps {
  onClose: () => void;
}

export interface CloseSubmitProps<S = undefined> extends CloseProps {
  onSubmit: S extends undefined ? () => void : (data: S) => void;
}

export const Modal = ({ children, title, onClose }: Props) => (
  <div className="modal">
    <div className="content">
      <div className="title">
        <h2>{title}</h2>
        <FontAwesomeIcon
          icon="times"
          className="close-icon"
          onClick={() => onClose()}
        />
      </div>
      {children}
    </div>
  </div>
);
