import { Certificate } from '../../model/Certificate';
import { PaginatedTable } from '../table/PaginatedTable';
import { PagedData } from '../../hooks/usePagedData';
import { MemberLink } from '../MemberLink';

export interface Props {
  certificates: PagedData<Certificate>;
  memberId?: number;
}

export const CertificatesTable = ({ certificates, memberId }: Props) => (
  <PaginatedTable
    pagedData={certificates}
    columns={[
      {
        name: 'Naam',
        valueFn: (c) => c.fullName,
      },
      !memberId && {
        name: 'Eigenaar',
        valueFn: (c) => (c.owner ? <MemberLink member={c.owner} /> : '-'),
      },
    ]}
  />
);
