import { FormEvent, ReactNode, useCallback, useState } from 'react';
import { classNames } from '../../utils/classNames';
import { Button } from './Button';
import { ApiError, asApiError } from '../../services/ApiError';

type ChildFn = (props: { submitLoading: boolean }) => ReactNode;

export interface Props {
  children: ReactNode | ChildFn;
  onSubmit: () => void | Promise<void>;
  submitLabel?: string;
  submitDisabled?: boolean;
  className?: string;
}

export const Form = ({
  children,
  onSubmit,
  submitLabel,
  submitDisabled,
  className,
}: Props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [apiError, setApiError] = useState<ApiError>();

  const submitForm = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setApiError(undefined);
      setSubmitLoading(true);

      try {
        await onSubmit();
      } catch (e) {
        setApiError(asApiError(e));
      } finally {
        setSubmitLoading(false);
      }
    },
    [onSubmit]
  );

  return (
    <form onSubmit={submitForm} className={classNames('form', className)}>
      {typeof children === 'function' ? children({ submitLoading }) : children}
      {submitLabel && (
        <div className="buttons">
          <Button
            type="submit"
            text={submitLabel}
            loading={submitLoading}
            disabled={submitDisabled}
          />
        </div>
      )}
      {apiError && (
        <div className="error-message">
          {apiError.message}
          {apiError.errors && (
            <ul>
              {apiError.errors.map((e, i) => (
                <li key={i}>{Object.values(e.constraints)[0]}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </form>
  );
};
