import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export type setFormField<D> = <F extends keyof D>(
  field: F
) => (value: D[F]) => void;

export function useFormValue<D>(
  initialState: D | (() => D)
): [D, setFormField<D>, Dispatch<SetStateAction<D>>] {
  const [value, setValue] = useState(initialState);

  const setField = useCallback(
    <F extends keyof D>(field: F) =>
      (value: D[F]) =>
        setValue((prevState) => ({ ...prevState, [field]: value })),
    []
  );

  return [value, setField, setValue];
}
