import { JsonApiService } from './JsonApiService';
import { Injector } from 'react-service-injector';
import { Page, PageParameters } from '../model/Page';
import {
  CreateMember,
  Member,
  MembersFilter,
  MemberSummary,
  UpdateMember,
} from '../model/Member';

export class MembersService {
  private readonly api: JsonApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(JsonApiService);
    this.listMembers = this.listMembers.bind(this);
  }

  public listMembers(
    page: PageParameters,
    filter?: MembersFilter
  ): Promise<Page<MemberSummary>> {
    return this.api.jsonGet(`/api/members`, { ...page, ...filter });
  }

  public createMember(member: CreateMember): Promise<Member> {
    return this.api.jsonPost('/api/members', member);
  }

  public getMember(id: number): Promise<MemberSummary> {
    return this.api.jsonGet(`/api/members/${id}`);
  }

  public updateMember(id: number, updates: UpdateMember): Promise<void> {
    return this.api.jsonPut(`/api/members/${id}`, updates);
  }

  public archiveMember(id: number): Promise<void> {
    return this.api.jsonPost(`/api/members/${id}/archive`, {});
  }

  public unarchiveMember(id: number): Promise<void> {
    return this.api.jsonPost(`/api/members/${id}/unarchive`, {});
  }
}
